<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>地區管理</div>
    </div>
    <div class="card card-default">
      <div class="card-body">
        <v-client-table :data="districts" :columns="tableConfigs.columns" :options="tableConfigs.options">
          <template #status="props">
            <el-switch
                v-model="props.row.status"
                active-text="啟用"
                inactive-text="禁用"
                @change="handlerChangeStatus(props.row.id, props.row.status)"
            >
            </el-switch>
          </template>

          <template #tax_free="props">
            <span v-if="props.row.tax_free">{{ props.row.tax_free }}{{ props.row.currency }}</span>
            <span v-else>-</span>
          </template>

          <template #operation="props">
            <el-button style="margin-left: 10px;" size="mini" type="primary" @click="openDialog(props.row.id)">編輯</el-button>
          </template>

        </v-client-table>
      </div>

      <el-dialog
          title="编辑地区"
          :visible.sync="dialogVisible"
          :append-to-body="true"
          width="60%"
      >
        <el-form ref="form" :model="form" label-width="12rem" v-if="form">
          <el-form-item prop="name" label="名称">
            <el-input v-model="form.name" clearable disabled />
          </el-form-item>

          <el-form-item prop="name" label="區域群組">
            <el-select class="w-100" v-model="form.group_id" placeholder="请选择區域群組">
              <el-option
                  label="無"
                  value="">
              </el-option>
              <el-option
                  v-for="item in groups"
                  :key="item.id"
                  :label="`${item.id}：${item.name}`"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="zipcodes_str" label="偏遠地區">
            <el-input
                type="textarea"
                autosize
                placeholder="偏遠地區郵編，換行隔開"
                v-model="form.zipcodes_str"
            />
          </el-form-item>

          <el-form-item prop="currency" label="商品計算貨幣">
            <el-input v-model="form.currency" :maxlength="3" placeholder="空白默認為HKD" />
          </el-form-item>

          <el-form-item prop="tax_free" label="免稅額">
            <el-input-number v-model="form.tax_free" :step="100" :min="0" />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="save()">保存</el-button>
          </el-form-item>

        </el-form>
      </el-dialog>

    </div>
  </ContentWrapper>
</template>

<script>
import _ from 'lodash';
import { getLoadingInstance, showErrorMessage } from '@/helpers';

export default {
  name: 'District',
  data() {
    return {
      tableConfigs: {
        columns: [
          'tc_name', 'iso_code', 'group',
          'tax_free', 'currency',
          'status',
          'operation',
        ],
        options: {
          headings: {
            tc_name: '地區',
            iso_code: 'iso_code',
            group: '區域群組',
            status: '狀態',
            operation: '操作',
            currency: '商品計算貨幣',
            tax_free: '免稅額',
          },
          sortable: [],
          filterable: true,
          perPage: 50,
          perPageValues: [50, 100, 200, 300],
        },
      },
      dialogVisible: false,
      form: {
        name: '',
        zipcodes_str: '',
        group_id: null,
        id: null,
        currency: '',
        tax_free: 0,
      },
      currentId: null,
      groups: [],
      districts: [],
      props: {
        label: 'name',
        children: 'nodes',
      },
    };
  },
  mounted() {
    this.getData();
    const loading2 = getLoadingInstance();
    this.$api.group.get().then(({ data: response }) => {
      this.groups = response.data;
    }).catch(errors => showErrorMessage(errors)).finally(() => {
      loading2.close();
    });
  },
  methods: {
    getData() {
      const loading = getLoadingInstance();
      this.$api.district.root().then(({ data: response }) => {
        this.districts = response.data;
      }).catch(errors => showErrorMessage(errors)).finally(() => {
        loading.close();
      });
    },
    openDialog(id) {
      this.form = _.find(this.districts, { id });
      this.dialogVisible = true;
    },
    save() {
      const loading = getLoadingInstance();
      this.$api.district.update(this.form.id, this.form).then(() => {
        this.dialogVisible = false;
        this.getData();
      }).catch(error => showErrorMessage(error)).finally(() => {
        loading.close();
      });
    },
    chooseGroup(id, group_id) {
      this.$api.district.update(id, { group_id });
    },
    handlerChangeStatus(id, status) {
      this.$api.district.update(id, { status });
      const index = _.findIndex(this.districts, { id });
      this.districts[index].status = status;
    },
    handlerChangeZipcodes(data, id) {
      this.$api.district.update(id, { zipcodes_str: data.target.value });
    },
  },
};
</script>

<style lang="scss" scoped>
  .el-tree {
    .el-tree-node__content {
      height: 200px!important;
      /*margin: 20px 0!important;*/
    }
  }
 .district-tree-node {
   flex: 1;
   display: flex;
   align-items: center;
   justify-content: space-between;
   span {
     height: 1.5rem;
     line-height: 1.5rem;
   }
   .name {
     &.disable {
       color: gray;
       text-decoration: line-through;
     }
   }
 }
</style>
